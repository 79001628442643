import { Field } from "formik";
import React, { useEffect, useState } from "react";
import { Accordion, Button, Card } from "react-bootstrap";
import { Upload } from "antd";
import Select from "react-select";
import CustomSelectMulti from "../components/CustomSelectMulti";
import DeclareDeath from "../components/DeclareDeath";
import SingleSelectInput from "../components/SingleSelectInput";
// import { answererRelation, bloodgroup, casteList, marriage, mothertongue, occupationList, religionList } from '../data/advanceSearch';
import {
	abroadCountry,
	abroadCountryQuestion,
	abroadReason,
	abroadReasonQuestion,
	abroadYearQuestion,
	ageQuestion,
	answererRelation,
	answererRelationQuestion,
	bloodgroup,
	bloodgroupQuestion,
	casteList,
	casteQuestion,
	contactNo,
	dateQuestion,
	disabilitytype,
	disabilitytypeQuestion,
	education,
	educationQuestion,
	firstMarriageAgeQn,
	gender,
	genderQuestion,
	healthcondition,
	healthconditionQuesiton,
	ifOtherValueQuestion,
	insuranceListQuestion,
	insuranceLists,
	insuranceQuestion,
	insuranceYesNo,
	isGoingToSchool,
	isGoingToSchoolQn,
	livingStatus,
	livingStatusQuestion,
	marriage,
	marriageQuestion,
	memberName,
	mentalCardQuestion,
	mentalCardtype,
	mentalCardTypeQuestion,
	mentalCardYesNo,
	mentaltype,
	mentaltypeQuestion,
	mentalyesno,
	mentalYesNoQuestion,
	mothertongue,
	mothertongueQuestion,
	notGoingSchool,
	notGoingSchoolQn,
	occupation,
	occupationQuestion,
	other,
	primary,
	primaryQuestion,
	religionList,
	religionQuestion,
	secondary,
	secondaryQuestion,
	skillDetail,
	skillDetailQuestion,
	skillJobNoList,
	skillJobNoQn,
	skillJobQn,
	skillJobYesNo,
	skillTraining,
	skillTrainingDurationQn,
	skillTrainingQuestion,
	skillYesNo,
	socialSecurityAllowanceList,
	socialSecurityAllowanceQn,
	studyField,
	studyFieldQn,
	totalMember,
	unhealthy,
	unhealthyQuestion,
	votercard,
	votercardQuestion,
} from "../data/houseSurvey";
import { selectedLanguage, ward, wardQuestion } from "../data/institute";

const PariwarikBibaran = (props) => {
	const [memberCount, setMemberCount] = useState(0);
	const [repeaterForm, setRepeaterForm] = useState([]);
	const [showDeclareDeath, setShowDeclareDeath] = useState(false);
	const [familyId, setFamilyId] = useState("");
	const { formik } = props;
	const { familyDetail } = formik.values;
	const handleFamilyCountChange = (e) => {
		formik.handleChange(e);
		if (parseInt(e.target.value)) {
			setMemberCount(parseInt(e.target.value));
		} else {
			setMemberCount(0);
		}
	};
	useEffect(() => {
		console.log(formik.values.totalMember);
		const formMemberCount = formik.values.totalMember || 0;
		const repeaterForms = [...Array(formMemberCount).keys()];
		setRepeaterForm(repeaterForms);
	}, [formik.values.totalMember]);
	useEffect(() => {
		if (familyDetail.totalMember > 0) {
			setMemberCount(familyDetail.totalMember);
			formik.setValues({
				...formik.values,
				totalMember: familyDetail.totalMember,
			});
		}
	}, []);
	const showDeathForm = (familyId) => {
		setShowDeclareDeath(true);
		setFamilyId(familyId);
	};
	const removeRepeaterForm = (index) => {
		var array = [...repeaterForm]; // make a separate copy of the array
		if (index !== -1) {
			array.splice(index, 1);
			setRepeaterForm(array);
			const newMemberCount = memberCount - 1;
			setMemberCount(newMemberCount);
		}
	};
	const getSingleFamilyMember = (ind) => (
		<>
			<div className="row">
				<div className="col-12 d-flex justify-content-end mb-4 align-items-center">
					<i className="flaticon2-trash mr-3"></i>
					<Button
						variant="light-danger"
						onClick={() => showDeathForm(familyDetail[`_familyId${ind}`])}
					>
						Declare death
					</Button>
				</div>
			</div>
			<div className="row mb-7">
				<div className="col-lg-6">
					<div class="form-group">
						<label>{memberName[selectedLanguage]}</label>
						<input
							type="text"
							class="form-control"
							name={`familyDetail.fullName${ind}`}
							placeholder={memberName[selectedLanguage]}
							onChange={formik.handleChange}
							value={familyDetail[`fullName${ind}`]}
						/>
					</div>
				</div>
				<div className="col-lg-6">
					<div class="form-group">
						<label>{answererRelationQuestion[selectedLanguage]}</label>
						<SingleSelectInput
							id="answererRelation"
							name={`familyDetail.answererRelation${ind}`}
							value={familyDetail[`answererRelation${ind}`]}
							formik={formik}
							selectList={answererRelation}
						/>
					</div>
				</div>
				{familyDetail[`answererRelation${ind}`] === "other" && (
					<div className="col-lg-6">
						<div class="form-group">
							<label>अन्य भए उल्लेख गर्नुहोस |</label>
							<input
								type="text"
								class="form-control"
								name={`familyDetail.answererRelation${ind}Other`}
								onChange={formik.handleChange}
								placeholder=""
								value={familyDetail[`answererRelation${ind}Other`]}
							/>
						</div>
					</div>
				)}
				<div className="col-lg-6">
					<label className="form-control-label">
						{dateQuestion[selectedLanguage]}
					</label>
					<input
						type="text"
						className="form-control"
						id="example-date-input"
						placeholder={`${dateQuestion[selectedLanguage]}(YYYY-MM-DD)`}
						name={`familyDetail.dateOfBirth${ind}`}
						onChange={formik.handleChange}
						value={familyDetail[`dateOfBirth${ind}`]}
					/>
				</div>
				<div className="col-lg-6">
					<div class="form-group">
						<label>{ageQuestion[selectedLanguage]}</label>
						<input
							type="number"
							class="form-control"
							name={`familyDetail.age${ind}`}
							placeholder=""
							onChange={formik.handleChange}
							value={familyDetail[`age${ind}`]}
						/>
					</div>
				</div>
				<div className="col-lg-6">
					<div class="form-group">
						<label>{contactNo[selectedLanguage]}</label>
						<input
							type="number"
							class="form-control"
							name={`familyDetail.contactNumber${ind}`}
							placeholder=""
							onChange={formik.handleChange}
							value={familyDetail[`contactNumber${ind}`]}
						/>
					</div>
				</div>
				<div className="col-lg-6">
					<div class="form-group">
						<label>{genderQuestion[selectedLanguage]}</label>
						<SingleSelectInput
							id="gender"
							name={`familyDetail.gender${ind}`}
							value={familyDetail[`gender${ind}`]}
							formik={formik}
							selectList={gender}
						/>
					</div>
				</div>
				<div className="col-lg-6">
					<div class="form-group">
						<label>{livingStatusQuestion[selectedLanguage]}</label>
						<SingleSelectInput
							id="livingStatus"
							name={`familyDetail.livingStatus${ind}`}
							value={familyDetail[`livingStatus${ind}`]}
							formik={formik}
							selectList={livingStatus}
						/>
					</div>
				</div>

				{familyDetail[`livingStatus${ind}`] === "foreign" && (
					<>
						<div className="col-lg-6">
							<div class="form-group">
								<label>{abroadReasonQuestion[selectedLanguage]}</label>
								<select
									className="form-control"
									id="abroadReason"
									name={`familyDetail.abroadReason${ind}`}
									onChange={formik.handleChange}
									value={familyDetail[`abroadReason${ind}`]}
								>
									{abroadReason.map((value) => (
										<option value={value.value}>
											{value[selectedLanguage]}
										</option>
									))}
								</select>
							</div>
						</div>
						{familyDetail[`abroadReason${ind}`] === "other" && (
							<div className="col-lg-6">
								<div class="form-group">
									<label>{other[selectedLanguage]}</label>
									<input
										type="text"
										class="form-control"
										name={`familyDetail.abroadReason${ind}Other`}
										placeholder=""
										onChange={formik.handleChange}
										value={familyDetail[`abroadReason${ind}Other`]}
									/>
								</div>
							</div>
						)}
						<div className="col-lg-6">
							<div class="form-group">
								<label>{abroadYearQuestion[selectedLanguage]}</label>
								<input
									type="number"
									class="form-control"
									name={`familyDetail.bideshiyekoYear${ind}`}
									placeholder=""
									onChange={formik.handleChange}
									value={familyDetail[`bideshiyekoYear${ind}`]}
								/>
							</div>
						</div>
						<div className="col-lg-6">
							<div class="form-group">
								<label>{abroadCountryQuestion[selectedLanguage]}</label>
								<select
									className="form-control"
									id="abroadCountry"
									name={`familyDetail.abroadCountry${ind}`}
									onChange={formik.handleChange}
									value={familyDetail[`abroadCountry${ind}`]}
								>
									{abroadCountry.map((value) => (
										<option value={value.value}>
											{value[selectedLanguage]}
										</option>
									))}
								</select>
							</div>
						</div>
						{familyDetail[`abroadCountry${ind}`] === "other" && (
							<div className="col-lg-6">
								<div class="form-group">
									<label>अन्य भए उल्लेख गर्नुहोस |</label>
									<input
										type="text"
										class="form-control"
										name={`familyDetail.abroadCountry${ind}Other`}
										placeholder=""
										onChange={formik.handleChange}
										value={familyDetail[`abroadCountry${ind}Other`]}
									/>
								</div>
							</div>
						)}
					</>
				)}
				<div className="col-lg-6">
					<div class="form-group">
						<label>{casteQuestion[selectedLanguage]}</label>
						<SingleSelectInput
							id="casteList"
							name={`familyDetail.caste${ind}`}
							value={familyDetail[`caste${ind}`]}
							formik={formik}
							selectList={casteList}
						/>
					</div>
				</div>
				{familyDetail[`caste${ind}`] === "other" && (
					<div className="col-lg-6">
						<div class="form-group">
							<label>अन्य भए उल्लेख गर्नुहोस |</label>
							<input
								type="text"
								class="form-control"
								name={`familyDetail.caste${ind}Other`}
								placeholder=""
								onChange={formik.handleChange}
								value={familyDetail[`casteList${ind}Other`]}
							/>
						</div>
					</div>
				)}
				<div className="col-lg-6">
					<div class="form-group">
						<label>{religionQuestion[selectedLanguage]}</label>
						<SingleSelectInput
							id="casteList"
							name={`familyDetail.religionList${ind}`}
							value={familyDetail[`religionList${ind}`]}
							formik={formik}
							selectList={religionList}
						/>
					</div>
				</div>
				{familyDetail[`religionList${ind}`] === "other" && (
					<div className="col-lg-6">
						<div class="form-group">
							<label>अन्य भए उल्लेख गर्नुहोस |</label>
							<input
								type="text"
								class="form-control"
								name={`familyDetail.religionList${ind}Other`}
								placeholder=""
								onChange={formik.handleChange}
								value={familyDetail[`religionList${ind}Other`]}
							/>
						</div>
					</div>
				)}
				<div className="col-lg-6">
					<div class="form-group">
						<label>{marriageQuestion[selectedLanguage]}</label>
						<SingleSelectInput
							id="casteList"
							name={`familyDetail.marriage${ind}`}
							value={familyDetail[`marriage${ind}`]}
							formik={formik}
							selectList={marriage}
						/>
					</div>
				</div>
				<div className="col-lg-6">
					<div class="form-group">
						<label>{isGoingToSchoolQn[selectedLanguage]}</label>
						<div class="radio-inline">
							{isGoingToSchool.map((value, i) => (
								<label class="radio radio-rounded">
									<input
										type="radio"
										name={`familyDetail.isSchoolGoing${ind}`}
										defaultChecked={
											familyDetail[`isSchoolGoing${ind}`] == value.value
										}
										value={value.value}
										onChange={formik.handleChange}
										defaultChecked={
											familyDetail[`isSchoolGoing${ind}`] == value.value
										}
									/>
									<span></span>
									{value[selectedLanguage]}
								</label>
							))}
						</div>
					</div>
				</div>
				{familyDetail[`isGoingToSchool${ind}`] == "not_going" && (
					<>
						<div className="col-lg-6">
							<div class="form-group">
								<label>{notGoingSchoolQn[selectedLanguage]}</label>
								<select
									className="form-control"
									id="notGoingSchool"
									name={`familyDetail.whyNoSchool${ind}`}
									onChange={formik.handleChange}
									value={familyDetail[`whyNoSchool${ind}`]}
								>
									{notGoingSchool.map((value) => (
										<option value={value.value}>
											{value[selectedLanguage]}
										</option>
									))}
								</select>
							</div>
						</div>
						{familyDetail[`whyNoSchool${ind}`] === "other" && (
							<div className="col-lg-6">
								<div class="form-group">
									<label>{ifOtherValueQuestion[selectedLanguage]}</label>
									<input
										type="text"
										class="form-control"
										name={`familyDetail.whyNoSchool${ind}Other`}
										onChange={formik.handleChange}
										value={familyDetail[`whyNoSchool${ind}Other`]}
									/>
								</div>
							</div>
						)}
					</>
				)}
				<div className="col-lg-6">
					<div class="form-group">
						<label>{educationQuestion[selectedLanguage]}</label>
						<SingleSelectInput
							id="education"
							name={`familyDetail.education${ind}`}
							value={familyDetail[`education${ind}`]}
							formik={formik}
							selectList={education}
						/>
					</div>
				</div>
				{familyDetail[`education${ind}`] === "primary" && (
					<div className="col-lg-6">
						<div class="form-group">
							<label>{primaryQuestion[selectedLanguage]}</label>
							<select
								className="form-control"
								id="primary"
								name={`familyDetail.primary${ind}`}
								onChange={formik.handleChange}
								value={familyDetail[`primary${ind}`]}
							>
								{primary.map((value) => (
									<option value={value.value}>{value[selectedLanguage]}</option>
								))}
							</select>
						</div>
					</div>
				)}
				{familyDetail[`education${ind}`] === "secondary" && (
					<div className="col-lg-6">
						<div class="form-group">
							<label>{secondaryQuestion[selectedLanguage]}</label>
							<select
								className="form-control"
								id="secondary"
								name={`familyDetail.secondary${ind}`}
								onChange={formik.handleChange}
								value={familyDetail[`secondary${ind}`]}
							>
								{secondary.map((value) => (
									<option value={value.value}>{value[selectedLanguage]}</option>
								))}
							</select>
						</div>
					</div>
				)}
				{["bachelors", "masters", "m_phil", "phd"].includes(
					familyDetail[`education${ind}`]
				) && (
					<>
						<div className="col-lg-6">
							<div class="form-group">
								<label>{studyFieldQn[selectedLanguage]}</label>
								<CustomSelectMulti
									formik={formik}
									value={familyDetail[`studyField${ind}`]}
									isMulti={true}
									list={studyField}
									field={`familyDetail.studyField${ind}`}
								/>
							</div>
						</div>
						{familyDetail[`studyField${ind}`]?.includes("other") && (
							<div className="col-lg-6">
								<div class="form-group">
									<label>{ifOtherValueQuestion[selectedLanguage]}</label>
									<input
										type="text"
										class="form-control"
										name={`familyDetail.studyField${ind}Other`}
										onChange={formik.handleChange}
										value={familyDetail[`studyField${ind}Other`]}
									/>
								</div>
							</div>
						)}
					</>
				)}
				<div className="col-lg-6">
					<div class="form-group">
						<label>{firstMarriageAgeQn[selectedLanguage]}</label>
						<input
							type="number"
							class="form-control"
							name={`familyDetail.firstMarriageAge${ind}`}
							onChange={formik.handleChange}
							value={familyDetail[`firstMarriageAge${ind}`]}
						/>
					</div>
				</div>
				<div className="col-lg-6">
					<div class="form-group">
						<label>{occupationQuestion[selectedLanguage]}</label>
						<SingleSelectInput
							id="occupation"
							name={`familyDetail.occupation${ind}`}
							value={familyDetail[`occupation${ind}`]}
							formik={formik}
							selectList={occupation}
						/>
					</div>
				</div>
				{familyDetail[`occupation${ind}`] === "other" && (
					<div className="col-lg-6">
						<div class="form-group">
							<label>अन्य भए उल्लेख गर्नुहोस |</label>
							<input
								type="text"
								class="form-control"
								name={`familyDetail.occupation${ind}Other`}
								placeholder=""
								onChange={formik.handleChange}
								value={familyDetail[`occupation${ind}Other`]}
							/>
						</div>
					</div>
				)}
				<div className="col-lg-6">
					<div class="form-group">
						<label>{healthconditionQuesiton[selectedLanguage]}</label>
						<div class="radio-inline">
							{healthcondition.map((value, i) => (
								<label class="radio radio-rounded">
									<input
										type="radio"
										name={`familyDetail.healthcondition${ind}`}
										value={value.value}
										onChange={formik.handleChange}
										defaultChecked={
											familyDetail[`healthcondition${ind}`] == value.value
										}
									/>
									<span></span>
									{value[selectedLanguage]}
								</label>
							))}
						</div>
					</div>
				</div>
				{familyDetail[`healthcondition${ind}`] === "unhelathy" && (
					<div className="col-lg-6">
						<div class="form-group">
							<label>{unhealthyQuestion[selectedLanguage]}</label>

							<CustomSelectMulti
								formik={formik}
								value={familyDetail[`unhealthy${ind}`]}
								isMulti={true}
								list={unhealthy}
								field={`familyDetail.unhealthy${ind}`}
							/>
							{/* <Select
								onChange={(e) =>
									formik.setFieldValue(`familyDetail[unhealthy${ind}`, e.value)
								}
								value={familyDetail[`unhealthy${ind}`]}
								isMulti={true}
								options={unhealthy.map((child, i) => {
									return {
										value: child.value,
										label: child.nepali,
									};
								})}
							/> */}
						</div>
					</div>
				)}
				<div className="col-lg-6">
					<div class="form-group">
						<label>{insuranceQuestion[selectedLanguage]}</label>
						<div class="radio-inline">
							{insuranceYesNo.map((value, i) => (
								<label class="radio radio-rounded">
									<input
										type="radio"
										name={`familyDetail.insurance${ind}`}
										value={value.value}
										onChange={formik.handleChange}
										defaultChecked={
											familyDetail[`insurance${ind}`] == value.value
										}
									/>
									<span></span>
									{value[selectedLanguage]}
								</label>
							))}
						</div>
					</div>
				</div>
				{familyDetail[`insurance${ind}`] === "insurance" && (
					<div className="col-lg-6">
						<div class="form-group">
							<label>{insuranceListQuestion[selectedLanguage]}</label>

							<CustomSelectMulti
								formik={formik}
								value={familyDetail[`insuranceLists${ind}`]}
								isMulti={true}
								list={insuranceLists}
								field={`familyDetail.insuranceLists${ind}`}
							/>
							{/* <Select
								onChange={(e) =>
									formik.setFieldValue(
										`familyDetail.insuranceLists${ind}`,
										e.value
									)
								}
								value={familyDetail[`insuranceLists${ind}`]}
								isMulti={true}
								options={insuranceLists.map((child, i) => {
									return {
										value: child.value,
										label: child.nepali,
									};
								})}
							/> */}
						</div>
					</div>
				)}
				<div className="col-lg-6">
					<div class="form-group">
						<label>{mothertongueQuestion[selectedLanguage]}</label>
						<SingleSelectInput
							id="mothertongue"
							name={`familyDetail.mothertongue${ind}`}
							value={familyDetail[`mothertongue${ind}`]}
							formik={formik}
							selectList={mothertongue}
						/>
					</div>
				</div>
				{familyDetail[`mothertongue${ind}`] === "other" && (
					<div className="col-lg-6">
						<div class="form-group">
							<label>अन्य भए उल्लेख गर्नुहोस |</label>
							<input
								type="text"
								class="form-control"
								name={`familyDetail.mothertongueOther${ind}`}
								placeholder=""
								onChange={formik.handleChange}
								value={familyDetail[`mothertongueOther${ind}`]}
							/>
						</div>
					</div>
				)}
				<div className="col-lg-6">
					<div class="form-group">
						<label>{bloodgroupQuestion[selectedLanguage]}</label>
						<SingleSelectInput
							id="bloodgroup"
							name={`familyDetail.bloodgroup${ind}`}
							value={familyDetail[`bloodgroup${ind}`]}
							formik={formik}
							selectList={bloodgroup}
						/>
					</div>
				</div>
				{/* <div className="col-lg-6">
                    <div class="form-group">
                        <label>{votercardQuestion[selectedLanguage]}</label>
                        <div class="radio-inline">
                            {
                                votercard.map((value, i) => (
                                    <label class="radio radio-rounded">
                                        <input type="radio" name={`familyDetail.votercard${ind}`} value={value.value} onChange={formik.handleChange} defaultChecked={familyDetail[`votercard${ind}`] == value.value} />
                                        <span></span>
                                        {value[selectedLanguage]}
                                    </label>
                                ))
                            }
                        </div>
                    </div>
                </div> */}
				<div className="col-lg-6">
					<div class="form-group">
						<label>{skillTrainingQuestion[selectedLanguage]}</label>
						<div class="radio-inline">
							{skillTraining.map((value, i) => (
								<label class="radio radio-rounded">
									<input
										type="radio"
										name={`familyDetail.skillTraining${ind}`}
										value={value.value}
										onChange={formik.handleChange}
										defaultChecked={
											familyDetail[`skillTraining${ind}`] == value.value
										}
									/>
									<span></span>
									{value[selectedLanguage]}
								</label>
							))}
						</div>
					</div>
				</div>
				{familyDetail[`skillTraining${ind}`] === "skill_yes" && (
					<>
						<div className="col-lg-6">
							<div class="form-group">
								<label>{skillDetailQuestion[selectedLanguage]}</label>

								<CustomSelectMulti
									formik={formik}
									value={familyDetail[`skillDetail${ind}`]}
									isMulti={true}
									list={skillDetail}
									field={`familyDetail.skillDetail${ind}`}
								/>
								{/* <Select
									onChange={(e) =>
										formik.setFieldValue(
											`familyDetail.skillDetail${ind}`,
											e.value
										)
									}
									value={familyDetail[`skillDetail${ind}`]}
									isMulti={true}
									options={skillDetail.map((child, i) => {
										return {
											value: child.value,
											label: child.nepali,
										};
									})}
								/> */}
							</div>
						</div>
						{familyDetail[`skillDetail${ind}`]?.includes("other") && (
							<div className="col-lg-6">
								<div class="form-group">
									<label>{ifOtherValueQuestion[selectedLanguage]}</label>
									<input
										type="text"
										class="form-control"
										name={`familyDetail.skillTrainingOther${ind}`}
										onChange={formik.handleChange}
										value={familyDetail[`skillTrainingOther${ind}`]}
									/>
								</div>
							</div>
						)}
						<div className="col-lg-6">
							<div class="form-group">
								<label>{skillTrainingDurationQn[selectedLanguage]}</label>
								<input
									type="text"
									class="form-control"
									name={`familyDetail.trainingDuration${ind}`}
									onChange={formik.handleChange}
									value={familyDetail[`trainingDuration${ind}`]}
								/>
							</div>
						</div>
						<div className="col-lg-6">
							<div class="form-group">
								<label>{skillJobQn[selectedLanguage]}</label>
								<div class="radio-inline">
									{skillJobYesNo.map((value, i) => (
										<label class="radio radio-rounded">
											<input
												type="radio"
												name={`familyDetail.sameJobAsTraining${ind}`}
												value={value.value}
												onChange={formik.handleChange}
												defaultChecked={
													familyDetail[`sameJobAsTraining${ind}`] == value.value
												}
											/>
											<span></span>
											{value[selectedLanguage]}
										</label>
									))}
								</div>
							</div>
						</div>
						{familyDetail[`sameJobAsTraining${ind}`] === "not_same_job" && (
							<div className="col-lg-6">
								<div class="form-group">
									<label>{skillJobNoQn[selectedLanguage]}</label>
									<select
										className="form-control"
										id="mentalType"
										name={`familyDetail.skillJobNo${ind}`}
										onChange={formik.handleChange}
										value={familyDetail[`skillJobNo${ind}`]}
									>
										{skillJobNoList.map((value) => (
											<option value={value.value}>
												{value[selectedLanguage]}
											</option>
										))}
									</select>
								</div>
							</div>
						)}
					</>
				)}
				<div className="col-lg-6">
					<div class="form-group">
						<label>{mentalYesNoQuestion[selectedLanguage]}</label>
						<div class="radio-inline">
							{mentalyesno.map((value, i) => (
								<label class="radio radio-rounded">
									<input
										type="radio"
										name={`familyDetail.mentalyesno${ind}`}
										value={value.value}
										onChange={formik.handleChange}
										defaultChecked={
											familyDetail[`mentalyesno${ind}`] == value.value
										}
									/>
									<span></span>
									{value[selectedLanguage]}
								</label>
							))}
						</div>
					</div>
				</div>
				{familyDetail[`mentalyesno${ind}`] === "disability" && (
					<>
						<div className="col-lg-6">
							<div class="form-group">
								<label>{mentaltypeQuestion[selectedLanguage]}</label>
								<select
									className="form-control"
									id="mentalType"
									name={`familyDetail.mentaltype${ind}`}
									onChange={formik.handleChange}
									value={familyDetail[`mentaltype${ind}`]}
								>
									{mentaltype.map((value) => (
										<option value={value.value}>
											{value[selectedLanguage]}
										</option>
									))}
								</select>
							</div>
						</div>
						<div className="col-lg-6">
							<div class="form-group">
								<label>{disabilitytypeQuestion[selectedLanguage]}</label>
								<select
									className="form-control"
									id="disabilityType"
									name={`familyDetail.disabilityType${ind}`}
									onChange={formik.handleChange}
									value={familyDetail[`disabilityType${ind}`]}
								>
									{disabilitytype.map((value) => (
										<option value={value.value}>
											{value[selectedLanguage]}
										</option>
									))}
								</select>
							</div>
						</div>
						<div className="col-lg-6">
							<div class="form-group">
								<label>{mentalCardQuestion[selectedLanguage]}</label>
								<div class="radio-inline">
									{mentalCardYesNo.map((value, i) => (
										<label class="radio radio-rounded">
											<input
												type="radio"
												name={`familyDetail.mentalCardYesNo${ind}`}
												value={value.value}
												onChange={formik.handleChange}
												defaultChecked={
													familyDetail[`mentalCardYesNo${ind}`] == value.value
												}
											/>
											<span></span>
											{value[selectedLanguage]}
										</label>
									))}
								</div>
							</div>
						</div>
						{familyDetail[`mentalCardYesNo${ind}`] === "card_taken" && (
							<div className="col-lg-6">
								<div class="form-group">
									<label>{mentalCardTypeQuestion[selectedLanguage]}</label>
									<select
										className="form-control"
										id="mentalCardtype"
										name={`familyDetail.mentalCardtype${ind}`}
										onChange={formik.handleChange}
										value={familyDetail[`mentalCardtype${ind}`]}
									>
										{mentalCardtype.map((value) => (
											<option value={value.value}>
												{value[selectedLanguage]}
											</option>
										))}
									</select>
								</div>
							</div>
						)}
					</>
				)}
				<div className="col-lg-6">
					<div class="form-group">
						<label>{socialSecurityAllowanceQn[selectedLanguage]}</label>
						<SingleSelectInput
							id="socialSecurityAllowance"
							name={`familyDetail.socialSecurityAllowance${ind}`}
							value={familyDetail[`socialSecurityAllowance${ind}`]}
							formik={formik}
							selectList={socialSecurityAllowanceList}
						/>
					</div>
				</div>
				<div className="col-lg-6">
					<div class="form-group">
						<label>फोटो</label>
						{/* <input className="d-none" type="file" />
						<button className="btn btn-outline-primary">Upload</button> */}
						<Upload
							id="socialSecurityAllowance"
							// name={`familyDetail.socialSecurityAllowance${ind}`}
							// value={familyDetail[`socialSecurityAllowance${ind}`]}
							// formik={formik}
							// selectList={socialSecurityAllowanceList}
						>
							Test
						</Upload>
					</div>
				</div>
			</div>
			<hr />
		</>
	);
	return (
		<>
			<DeclareDeath
				familyId={familyId}
				show={showDeclareDeath}
				setShow={setShowDeclareDeath}
			/>
			<div class="row justify-content-center my-10 px-8 my-lg-15 px-lg-10">
				<div class="col-xl-12 col-xxl-8">
					<div
						class="pb-5"
						data-wizard-type="step-content"
						data-wizard-state="current"
					>
						<h3 class="mb-10 font-weight-bold text-dark">परिवार विवरण</h3>
						<div className="row">
							<div className="col-lg-6">
								<div class="form-group">
									<label>{totalMember[selectedLanguage]}</label>
									<input
										min="0"
										type="number"
										class="form-control"
										name="totalMember"
										onChange={(e) => handleFamilyCountChange(e)}
										placeholder={totalMember[selectedLanguage]}
										value={formik.values.totalMember}
									/>
								</div>
							</div>
						</div>
						{repeaterForm.map((ind) => (
							<Accordion defaultActiveKey={0}>
								<Card className="mb-2">
									<Card.Header>
										<Accordion.Toggle
											className="w-100 d-flex justify-content-between"
											as={Button}
											variant="light-primary"
											eventKey={ind}
											title="Click to expand/collapse"
										>
											<h5 className="mb-0">{familyDetail[`fullName${ind}`]}</h5>
										</Accordion.Toggle>
									</Card.Header>
									<Accordion.Collapse eventKey={ind}>
										<Card.Body>{getSingleFamilyMember(ind)}</Card.Body>
									</Accordion.Collapse>
								</Card>
							</Accordion>
						))}
					</div>
				</div>
			</div>
		</>
	);
};

export default PariwarikBibaran;
