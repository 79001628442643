import axiosHandler from "../../../redux/axiosHandler";

export function getReportData(base, detailName) {
	return axiosHandler.get(`/analysis/${base}/${detailName}`);
}

export function getTableData(ward) {
	return axiosHandler.get(`/house/list/${ward}`);
}
export function getHouseById(id) {
	return axiosHandler.get(`/house/listhouse/${id}`);
}
export function getHouseDetailsById(id) {
	return axiosHandler.get(`/searchhouseById/housesearchId/${id}`);
}
export function checkIfHouseExists(id) {
	return axiosHandler.get(`/house/isHouseNumberExist/${id}`);
}
export function homeSurveyDataPost(data) {
	return axiosHandler.post(`/housesurvey/survey`, data);
}
export function deleteHouseById(id) {
	return axiosHandler.get(`/house/delete/${id}`);
}
export function getAnalysisData(detail, body) {
	return axiosHandler.post(`/analysis/generic/${detail}`, body);
}

export function DeclareDeathSave(deathDetails) {
	return axiosHandler.post("/house/memberDeath", deathDetails);
}

export function getLogData() {
	return axiosHandler.get("/log/all");
}

export function getUsersListData() {
	return axiosHandler.get("/users/list");
}

export function deleteUsersById(id) {
	return axiosHandler.post(`/users/remove`, { userId: id });
}
