import Table from "rc-table";
import React, { useEffect, useState } from "react";
import { Button, Card, Modal } from "react-bootstrap";
import Loader from "../Loader";
import Pagination from "../Pagination";
import { privilege } from "../../data/global";
import { toast } from "react-toastify";
import {
	deleteUsersById,
	getUsersListData,
} from "../../modules/HomeSurvey/homeSurveyCrud";
import EditProfile from "./EditProfile";
import AddUser from "./AddUser";
import { fetchUserDetails } from "../../modules/Auth/_redux/authCrud";
import { selectedLanguage } from "../../data/institute";

const UsersList = (props) => {
	const [sessionRole] = useState(parseInt(sessionStorage.getItem("role")));
	const [sessionWard] = useState(parseInt(sessionStorage.getItem("ward")));
	const [columns, setColumns] = useState([]);
	const [loading, setLoading] = useState(true);
	const [data, setData] = useState([]);
	const [filteredData, setFilteredData] = useState([]);
	const [displayData, setDisplayData] = useState([]);
	const [totalPages, setTotalPages] = useState(0);
	const [showEditProfile, setShowEditProfile] = useState(false);
	const [showPreviewProfile, setShowPreviewProfile] = useState(false);
	const [deleteProfile, setDeleteProfile] = useState(false);
	const [addProfile, setAddProfile] = useState(false);
	const [usersId, setUserId] = useState("");
	const [deleteUserId, setDeleteUserId] = useState("");
	const [userByIdData, setUserData] = useState({});

	const deleteData = () => {
		console.log("deleteUserId", deleteUserId);
		deleteUsersById(deleteUserId).then((res) => {
			if (res.data) {
				const newData = data.filter((i) => i.user._id !== deleteUserId);
				const newFilteredData = filteredData.filter(
					(i) => i.user._id !== deleteUserId
				);
				const newDisplayData = displayData.filter(
					(i) => i.user._id !== deleteUserId
				);
				setData(newData);
				setFilteredData(newFilteredData);
				setDisplayData(newDisplayData);
				fetHouseList();
				toast.success("Data deleted successfully");
			}
		});
	};
	useEffect(() => {
		fetHouseList();
	}, []);
	useEffect(() => {}, [filteredData]);

	const getDeletePermission = (role, ward) => {
		if (sessionRole === 0) {
			if (role === 0) {
				return false;
			} else {
				return true;
			}
		} else if ([1, 2, 3, 4, 5].includes(sessionRole)) {
			if (role === 0 || role === 5) {
				return false;
			} else if ([1, 2, 3, 4].includes(role) && sessionWard === ward) {
				return true;
			}
		}
	};

	const getEditPermission = (role) => {
		if (sessionRole === 0) {
			return true;
		} else if ([1, 2, 3, 4, 5, 6].includes(sessionRole)) {
			if (role === 0) {
				return false;
			} else {
				return true;
			}
		}
	};

	const fetHouseList = () => {
		setColumns([
			{
				title: "क्र.श",
				dataIndex: "sNo",
				key: "sNo",
				width: null,
			},
			{
				title: "नाम",
				dataIndex: "name",
				key: "name",
				width: null,
			},
			{
				title: "ईमेल",
				dataIndex: "email",
				key: "email",
				width: null,
			},
			{
				title: "भूमिका",
				dataIndex: "role",
				key: "role",
				width: null,
			},
			{
				title: "गतिविधि",
				dataIndex: "",
				key: "d",
				render: function(val) {
					return (
						<div className="d-flex table-actions">
							<i
								onClick={(e) => handlePreviewDetailOpen(val.user._id)}
								className="flaticon-eye mr-3 mb-3"
							></i>

							{getEditPermission(val.roleNumber) && (
								<i
									onClick={(e) => handlePreviewOpen(val.user._id)}
									className="flaticon-edit mr-3 mb-3"
								>
									{console.log("cuurentRole", val.roleNumber)}
								</i>
							)}

							{getDeletePermission(val.roleNumber, val.ward) && (
								<i
									className="flaticon-delete"
									onClick={(e) => handleDeleteModalOpen(val.user._id)}
								></i>
							)}
						</div>
					);
				},
			},
		]);

		getUsersListData()
			.then((res) => {
				if (res.data) {
					setLoading(false);
					const newData = res.data.map((datum, i) => {
						return {
							...datum,
							sNo: i + 1,
							name: datum.user.name.first,
							email: datum.user.email,
							role: getPrivilege(datum.role),
							roleNumber: datum.role,
						};
					});
					console.log("newData", newData);
					const pages = Math.ceil(res.data.length / 10);
					setTotalPages(pages);
					setData(newData);
					setFilteredData(newData);
					const dispData = newData.slice(0, 10);
					setDisplayData(dispData);
				}
			})
			.catch((err) => {
				setLoading(false);
			});
	};

	const getPrivilege = (data) => {
		return privilege.filter((each) => each.value === data)[0][selectedLanguage];
	};

	const getGender = (data) => {
		if (data === "female") return "महिला";
		else if (data === "male") return "पुरुष";
		else if (data === "other_sex") return "तेस्रो लिङ्गी";
		else return "";
	};

	const handlePreviewOpen = (id) => {
		setUserId(id);
		setShowEditProfile(true);
	};

	const handleEditProfileClose = () => {
		setUserId("");
		setShowEditProfile(false);
	};

	const handlePreviewDetailOpen = (id) => {
		setShowPreviewProfile(true);
		fetchUserById(id);
	};

	const handleDeleteModalOpen = (id) => {
		setDeleteUserId(id);
		setDeleteProfile(true);
	};

	const fetchUserById = (id) => {
		setLoading(true);
		fetchUserDetails(id)
			.then((res) => {
				setLoading(false);
				const data = res.data.pri.user;
				const userDetails = {
					email: data.email,
					firstname: data.name.first,
					gender: data.gender,
					id: id,
					lastname: data.name.last,
					username: data.username,
					role: getPrivilege(res.data.pri.role),
				};
				setUserData(userDetails);
			})
			.catch((err) => {
				setLoading(false);
				toast.error("An error occured while fetching user data");
			});
	};

	const handleAddPreviewOpen = () => {
		setAddProfile(true);
		fetHouseList();
	};

	const handlePageChange = (val) => {
		const start = val * 10;
		const end = start + 10;
		const dispData = filteredData.slice(start, end);
		setDisplayData(dispData);
	};

	const handleClose = () => setShowPreviewProfile(false);
	const handleDeleteClose = () => {
		setDeleteUserId("");
		setDeleteProfile(false);
	};

	const getContent = () => {
		return (
			<>
				{loading && <Loader loading={loading} />}
				{!loading && (
					<div className="text-center">
						<img
							alt="#"
							src="/media/svg/icons/user-icon.svg"
							style={{ width: "100px", height: "100px" }}
						/>
						<h1 className="" style={{ color: "#006DF0" }}>
							{userByIdData.role}
						</h1>
						<h3>
							{userByIdData.firstname} {userByIdData.lastname}
						</h3>
						<h5>{userByIdData.email} </h5>
						<h5>{getGender(userByIdData.gender)} </h5>
					</div>
				)}
			</>
		);
	};
	return (
		<>
			<Card style={{ width: "100%", minHeight: "80vh" }}>
				<Card.Body>
					<Card.Title>
						<h1 className="mb-10">प्रयोगकर्ताको सुची</h1>
					</Card.Title>
					{[0, 1].includes(sessionRole) && (
						<div className="row">
							<div className="col-12 d-flex justify-content-end align-items-center">
								<Button
									variant="light-primary"
									onClick={(e) => handleAddPreviewOpen()}
								>
									<i
										style={{ position: "relative", bottom: "1px" }}
										className="flaticon2-add-1 icon-nm"
									>
										थप्नुहोस
									</i>
								</Button>
							</div>
						</div>
					)}

					<EditProfile
						setShow={setShowEditProfile}
						handleDeleteClose={handleEditProfileClose}
						show={showEditProfile}
						id={usersId}
					/>
					<AddUser setShow={setAddProfile} show={addProfile} />
					{/* delete modal starts*/}
					<Modal centered show={deleteProfile} onHide={handleDeleteClose}>
						<Modal.Body>के तपाई यो डाटा हटाउन चाहनुहुन्छ?</Modal.Body>
						<Modal.Footer>
							<Button color="red" onClick={deleteData}>
								हटाउनहोस
							</Button>{" "}
							<Button color="secondary" onClick={handleDeleteClose}>
								रद्द गर्नुहोस
							</Button>
						</Modal.Footer>
					</Modal>
					{/* delete modal ends */}
					<Modal
						centered
						show={showPreviewProfile}
						onHide={handleClose}
						scrollable={true}
						dialogClassName="data-detail-modal"
						size="l"
						style={{ zIndex: "15000" }}
					>
						{/* <Modal.Header closeButton={true}>
							<Modal.Title>प्रयोगकर्ता सच्यानुहोस</Modal.Title>
						</Modal.Header> */}
						<Modal.Body>{getContent()}</Modal.Body>
					</Modal>

					{loading && <Loader loading={loading} />}
					{!loading && (
						<>
							<Table
								className="table table-hover"
								columns={columns}
								data={displayData}
								data-filter-control="true"
							/>
							<Pagination
								pages={totalPages}
								handlePageChange={handlePageChange}
							/>
						</>
					)}
				</Card.Body>
			</Card>
		</>
	);
};

export default UsersList;
